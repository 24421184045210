import React from 'react'

// Styles
import {
  blackWrapper,
  imgContainer,
  imgStyle,
  imgWrapper,
} from './styles.module.scss'

const Fanart: React.FC = () => {
  const fanarts = Array(51).fill(1)
  return (
    <>
      <div className={`block-wrapper ${blackWrapper}`}>
        <div className="container relative">
          <div className={imgContainer}>
            {fanarts.map((_, index) => (
              <div key={`fanart_${index}`} className={imgWrapper}>
                <img
                  src={`https://ficcofestival.com/img/fanart/${
                    index < 9 ? '0' : ''
                  }${index + 1}.png`}
                  className={imgStyle}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Fanart

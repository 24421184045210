import { navigate } from 'gatsby'
import React from 'react'

// Styles
import {
  header,
  headerImg,
  headerLink,
  headerContainer,
} from './styles.module.scss'

const Header: React.FC = () => {
  return (
    <div className={`${header} font-subscriber`}>
      <div className={headerContainer}>
        <div
          className={headerLink}
          onClick={() => {
            navigate('/')
          }}
        >
          Inicio
        </div>
        <div
          className={headerLink}
          onClick={() => {
            navigate('/invitados')
          }}
        >
          Invitados
        </div>
        <div
          className={headerLink}
          onClick={() => {
            navigate('/programa')
          }}
        >
          Programa
        </div>
        <div
          className={headerLink}
          onClick={() => {
            navigate('/fanart')
          }}
        >
          Fan arts
        </div>
      </div>
    </div>
  )
}

export default Header
